// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-source-prismic-graphql-components-preview-page-js": () => import("./../node_modules/gatsby-source-prismic-graphql/components/PreviewPage.js" /* webpackChunkName: "component---node-modules-gatsby-source-prismic-graphql-components-preview-page-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bastu-tider-js": () => import("./../src/pages/bastu-tider.js" /* webpackChunkName: "component---src-pages-bastu-tider-js" */),
  "component---src-pages-bli-medlem-js": () => import("./../src/pages/bli-medlem.js" /* webpackChunkName: "component---src-pages-bli-medlem-js" */),
  "component---src-pages-bli-sponsor-js": () => import("./../src/pages/bli-sponsor.js" /* webpackChunkName: "component---src-pages-bli-sponsor-js" */),
  "component---src-pages-boka-js": () => import("./../src/pages/boka.js" /* webpackChunkName: "component---src-pages-boka-js" */),
  "component---src-pages-bokning-js": () => import("./../src/pages/bokning.js" /* webpackChunkName: "component---src-pages-bokning-js" */),
  "component---src-pages-galleri-js": () => import("./../src/pages/galleri.js" /* webpackChunkName: "component---src-pages-galleri-js" */),
  "component---src-pages-historia-js": () => import("./../src/pages/historia.js" /* webpackChunkName: "component---src-pages-historia-js" */),
  "component---src-pages-hyra-js": () => import("./../src/pages/hyra.js" /* webpackChunkName: "component---src-pages-hyra-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nyheter-js": () => import("./../src/pages/nyheter.js" /* webpackChunkName: "component---src-pages-nyheter-js" */),
  "component---src-pages-om-js": () => import("./../src/pages/om.js" /* webpackChunkName: "component---src-pages-om-js" */),
  "component---src-pages-swipe-js": () => import("./../src/pages/swipe.js" /* webpackChunkName: "component---src-pages-swipe-js" */)
}

